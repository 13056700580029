<template>
  <div class="helper-window">
    <div class="wrapper">
      <div class="header">
        <p class="title">{{ $t('helperTranslater.helper') }}</p>
        <CloseIcon @click.native="$emit('close')" />
      </div>
      <div class="description">
        <p class="text">
          {{ $t('helper.description_text') }}
        </p>
      </div>
      <div class="accordeon">
        <div v-for="(item, index) in items" :key="index" class="accordeon-item">
          <div @click="toggleItem(item)" class="header">
            <p class="title">
              {{ item.title }}
            </p>
            <span v-if="!item.isExpanded">
              <ArrowDownIcon />
            </span>
            <span v-if="item.isExpanded">
              <ArrowDownIcon class="arrowHide" />
            </span>
          </div>
          <div v-if="item.isExpanded">
            <div v-if="item.value === 'objects'" class="content">
              <p class="text">
                {{ $t('helper.objects') }}
              </p>
              <a
                class="link"
                href="https://www.youtube.com/watch?v=3WJjEJnFfeY"
                target="_blank" rel="noopener noreferrer"
              >
                {{ $t('helper.video') }}
              </a>
            </div>
            <div v-if="item.value === 'sensors'" class="content">
              <p class="text">
                {{ $t('helper.content_text') }}
                <br />
                {{ $t('helper.content_text_list.one') }}
                <br />
                {{ $t('helper.content_text_list.two') }}
                <br />
                {{ $t('helper.content_text_list.three') }}
              </p>
              <img class="img" src="./assets/img1.png" alt="" />
              <p class="text">
                {{ $t('helper.content_text_list.four') }}
              </p>
              <p class="text">
                {{ $t('helper.content_text_list.five') }}
                <img src="./assets/Settings.png" alt="" />
              </p>
              <div class="inside-content">
                <p class="inside-text">
                  {{ $t('helper.content_text_list.six') }}
                </p>
                <p class="inside-text">
                  {{ $t('helper.content_text_list.seven') }}
                </p>
                <img class="inside-img" src="./assets/img2.png" alt="" />
                <a
                  class="inside-link"
                  href="https://www.youtube.com/watch?v=vlZbLaR4l0k"
                  target="_blank"
                >
                  {{ $t('helper.video_add_sensor') }}
                </a>
              </div>
            </div>
            <div v-if="item.value === 'reports'" class="content">
              <p class="text">
                {{ $t('helper.reports.text') }}
              </p>
              <a
                class="link"
                href="https://www.youtube.com/watch?v=GphkI5Cq7k0"
                target="_blank"
              >
                {{ $t('helper.reports.video_base_reports') }}
              </a>
              <a
                class="link"
                href="https://www.youtube.com/watch?v=_Y-cD86Rk1U"
                target="_blank"
              >
                {{ $t('helper.reports.video_create_template') }}
              </a>
            </div>
            <div v-if="item.value === 'monitoring'" class="content">
              <p class="text">{{ $t('helper.monitoring.text_one') }}</p>
              <p class="text">
                {{ $t('helper.monitoring.text_two') }}
              </p>
              <div class="colors">
                <div class="color">
                  <div class="circle-green"></div>
                  <p class="text">{{ $t('helper.monitoring.colors.one') }}</p>
                </div>
                <div class="color">
                  <div class="circle-yellow"></div>
                  <p class="text">{{ $t('helper.monitoring.colors.two') }}</p>
                </div>
                <div class="color">
                  <div class="circle-grey"></div>
                  <p class="text">{{ $t('helper.monitoring.colors.three') }}</p>
                </div>
              </div>
              <p class="text">{{ $t('helper.monitoring.statuses.text') }}</p>
              <div class="statuses">
                <div class="status">
                  <ParkingIcon />
                  <p class="text">{{ $t('helper.monitoring.statuses.one') }}</p>
                </div>
                <div class="status">
                  <ParkingKeyIcon />
                  <p class="text">{{ $t('helper.monitoring.statuses.two') }}</p>
                </div>
                <div class="status">
                  <MoveIcon />
                  <p class="text">
                    {{ $t('helper.monitoring.statuses.three') }}
                  </p>
                </div>
                <div class="status">
                  <QuestionIcon />
                  <p class="text">
                    {{ $t('helper.monitoring.statuses.four') }}
                  </p>
                </div>
              </div>
              <p class="text">
                {{ $t('helper.monitoring.text_three') }}
              </p>
              <img class="img" src="./assets/img3.png" alt="" />
            </div>
            <div v-if="item.value === 'admin'" class="content">
              <div class="text">
                {{ $t('helper.admin.text') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <router-link
      class="router-link"
      :to="{ path: '/dealer' }"
    >
      <div class="footer">
        <p class="title">{{ $t('helper.footer.account') }}</p>
        <p class="description">{{ $t('helper.footer.get_free') }}</p>
      </div>
    </router-link>

  </div>
</template>

<script>
import CloseIcon from './components/CloseIcon.vue'
import ArrowDownIcon from './components/ArrowDownIcon.vue'
import ParkingKeyIcon from './components/ParkingKeyIcon.vue'
import ParkingIcon from './components/ParkingIcon.vue'
import MoveIcon from './components/MoveIcon.vue'
import QuestionIcon from './components/QuestionIcon.vue'

export default {
  name: 'HelperWindow',
  components: {
    CloseIcon,
    ArrowDownIcon,
    ParkingIcon,
    ParkingKeyIcon,
    MoveIcon,
    QuestionIcon
  },
  data() {
    return {
      items: [
        {
          title: this.$t('helper.list.one.title'),
          value: 'objects',
          content: this.$t('helper.list.one.content'),
          isExpanded: false,
          link: this.$t('helper.list.one.link')
        },
        {
          title: this.$t('helper.list.two.title'),
          value: 'sensors',
          content: this.$t('helper.list.two.content'),
          isExpanded: false
        },
        {
          title: this.$t('helper.list.three.title'),
          value: 'reports',
          content: 'Content for item 3',
          isExpanded: false
        },
        {
          value: 'monitoring',
          title: this.$t('helper.list.four.title'),
          content: 'Content for item 4',
          isExpanded: false
        },
        {
          value: 'admin',
          title: this.$t('helper.list.five.title'),
          content: 'Content for item 5',
          isExpanded: false
        }
      ]
    }
  },
  methods: {
    toggleItem(item) {
      item.isExpanded = !item.isExpanded
    }
  }
}
</script>

<style lang="scss" scoped>
.helper-window {
  font-family: "Source Sans Pro", sans-serif;
  background: #fff;
  top: 0;
  bottom: 0;
  right: 0;
  width: 481px;
  position: absolute;
  z-index: 10;

  .wrapper {
    padding: 20px 25px;

    .header {
      display: flex;
      justify-content: space-between;

      .title {
        color: #446C9D;
        font-weight: 700;
        font-size: 24px;
        line-height: 120%;
      }

      .arrowHide {
        transform: rotate(180deg);
      }
    }

    .accordeon {
      margin-bottom: 12px;
      height: 700px;
      overflow: auto;

      .accordeon-item {
        background: #F6F7FA;
        padding: 14px 12px;
        margin-bottom: 12px;
        cursor: pointer;

        .title {
          display: block;
          font-weight: 600;
          font-size: 16px;
          line-height: 120%;
          color: #343434;

        }

        .content {
          display: flex;
          flex-wrap: wrap;

          .text {
            margin-top: 10px;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
          }

          .img {
            margin-top: 8px;
            margin-bottom: 12px;
          }

          .link {
            display: block;
            margin-top: 14px;
            font-weight: 600;
            font-size: 14px;
            line-height: 120%;
            color: #446C9D;
          }

          .inside-content {
            margin-top: 12px;
            background: #DBEBFF;
            padding: 10px 12px;

            .inside-text {
              margin-bottom: 8px;
              color: #20579A;
              font-weight: 400;
              font-size: 12px;
              line-height: 120%;
            }

            .inside-img {
              margin-bottom: 8px;
            }

            .inside-link {
              color: #446C9D;
              font-weight: 600;
              font-size: 14px;
              line-height: 120%;
            }
          }

          .colors {
            margin-top: 8px;

            .color {
              display: flex;
              align-items: center;
              margin-bottom: 8px;

              .circle-green {
                width: 14px;
                height: 14px;
                border-radius: 50%;
                background: #4ABC96;
              }

              .circle-yellow {
                width: 14px;
                height: 14px;
                border-radius: 50%;
                background: #F7CA5A;
              }

              .circle-grey {
                width: 14px;
                height: 14px;
                border-radius: 50%;
                background: #828D9B;
              }

              .text {
                margin-top: 0;
                margin-left: 5px;
              }

              &:last-child {
                margin-bottom: 0;
              }
            }

            .text {
              color: #828D9B;
              font-weight: 400;
              font-size: 12px;
              line-height: 140%;
            }
          }

          .statuses {
            .status {
              display: flex;
              align-items: center;
              margin-bottom: 10px;

              .text {
                margin-top: 0;
                margin-left: 5px;
                font-weight: 400;
                font-size: 12px;
                line-height: 140%;
                color: #828D9B;
              }

              &:first-child {
                margin-top: 8px;
              }
            }
          }
        }
      }
    }

    .description {
      background: #FFF1E9;
      border-radius: 3px;
      padding: 10px 12px;
      margin-top: 16px;
      margin-bottom: 12px;

      .text {
        color: #BD6039;
        font-weight: 400;
        font-size: 12px;
        line-height: 120%;
      }
    }
  }

  .footer {
    position: absolute;
    display: flex;
    flex-direction: column;
    bottom: 0;
    background: #388BF1;
    text-align: center;
    width: 100%;
    color: #fff;
    padding: 12px 0;
    min-height: 60px;
    justify-content: center;
    align-items: center;

    .title {
      font-weight: 600;
      font-size: 16px;
      line-height: 120%;
    }

    .description {
      margin-top: 4px;
      font-weight: 400;
      font-size: 12px;
      line-height: 100%;
    }
  }
}

</style>
